export const reviews = [
    {
        id: 1,
        name: 'Игорь Анисимов',
        text: 'Отличная компания с высоким профессионализмом! Наш заказ был выполнен в сжатые сроки, результат превзошел ожидания. Спасибо за качественную работу!'
    },
    {
        id: 2,
        name: 'Екатерина Михайлова',
        text: 'Заказывали обработку деталей для нашего производства. Работа выполнена точно и аккуратно. Мы довольны результатом и готовы рекомендовать эту фирму!'
    },
    {
        id: 3,
        name: 'Алексей Григорьев',
        text: 'Профессионализм на высшем уровне! Заказывали изготовление нестандартных деталей для наших машин. Все сделано быстро и качественно, цены приемлемые.'
    },
    {
        id: 4,
        name: 'Марина Кузнецова',
        text: 'Очень рады сотрудничеству с этой компанией! Изготовление деталей прошло гладко, без каких-либо проблем. Качество на высшем уровне, спасибо за вашу работу!'
    },
    {
        id: 5,
        name: ' Дмитрий Николаев',
        text: 'Заказывали обработку металлических конструкций. Работа выполнена с высоким стандартом, даже сложные детали изготовлены точно. Будем сотрудничать и в будущем.'
    },
    {
        id: 6,
        name: 'Ольга Смирнова',
        text: 'Эта компания стала нашим надежным партнером в сфере обработки металла. Профессиональный подход, ответственность, и всегда в срок! Спасибо за ваш труд'
    },
]