import React from 'react'

const ServiceHero = () => {

    return (
        <div className='service-hero'>
            <div className="service-hero-wrapper">
                <div className="hero-container container">
                    <h1 className='hero-motto'>
                        Услуги по металлообработке
                        и производству качественных и прочных изделий
                    </h1>


                    <div className="w-100">
                        <div className="row w-100">
                            <div className="col blue-text">Решаем задачи в день обращения</div>
                            <div className="col blue-text">Сжатые сроки производства</div>
                        </div>
                        <div className="row w-100 mt-4 mb-5">
                            <div className="col blue-text">Собственный парк оборудования</div>
                            <div className="col blue-text">Конкурентные цены и качество</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServiceHero
